/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  border-bottom: 1px solid var(--grey);
}

.contentContainer {
  padding: 10px;
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
}

.contentDescription {
  color: var(--secondary-text-color);
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}

.info {
  padding-left: 0;
}

.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.infoContainer a {
  color: var(--hl-color);
}

.infoContainer a:hover {
  color: #03b979;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--sm-spacing);
}

.downloadCV {
  margin-top: var(--sm-spacing);
  margin-bottom: var(--sm-spacing);
}

.about .socialIcons .icon {
  display: none;
}

.skills {
  margin-top: var(--spacing);
}

.skillCard {
  color: white;
  border-radius: 3px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  padding: 50px 0 !important;
}

.skillCard h4 {
  color: white;
  font-size: 30px;
  white-space: nowrap;
  font-weight: 400;
  margin-bottom: 15px;
  border-bottom: 2px solid var(--hl-color);
}

@media (min-width: 750px) {
  .about .socialIcons .icon {
    display: inline;
  }

  .about .socialIcons::before {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}
